import moment from "moment";
import React from "react";

export const formatDate = (timeDate) => {
  return moment(timeDate).format("D");

  // const date = new Date(timeDate);
  // const formattedDate = `${date.getDate()}`;
  // return <>{formattedDate}</>;
};

export const formatTimeMonth = (timeDate) => {
  return moment(timeDate).format("MMM");
  // const date = new Date(timeDate);
  // const formattedDate = `${date.getMonth()}`;
  // console.log("formattedDate", formattedDate);
  // const formatMonth = moment(formattedDate).format("MMMM").toUpperCase();
  // return <>{formatMonth}</>;
};

// export const formatTimeTicket = (timeDate) => {
//   const date = new Date(timeDate);
//   const formattedHour = `${date.getHours()}`;
//   const formattedMinutes = `${date.getMinutes()}`;
//   return (
//     <>
//       {formattedHour}h{formattedMinutes}
//     </>
//   );
// };

// export const formatDateMonthYear = (timeDate) => {
//   const date = new Date(timeDate);
//   const formattedDate = `${date.getDate()}`;
//   const formatMonthYear = moment(date).format("MMM YYYY");

//   return (
//     <>
//       {formattedDate} {formatMonthYear}
//     </>
//   );
// };
