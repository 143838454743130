import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
// import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { IconDown } from "../../constant/icon";
import { TEXT_COLOR, TEXT_SIZE } from "../textBaseV2/TextBaseV2";

const SIZE_SELECT = {
  sm: "py-2",
  md: "py-3",
  lg: "py-3.5",
};

const COLOR_SELECT = {
  filled: {
    normal: "border border-[#DDD7E7] rounded-lg",
    hover: "hover:border-[#1C64F2] hover:cursor-pointer ",
    // active: "ring-2 active:ring-primary-300",
    focus:
      "focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-1 focus-visible:ring-offset-[#1C64F2]",
    disable: "border-[#DDD7E7] cursor-not-allowed",
  },
  outline: {
    normal: "border-0 border-b border-[#DDD7E7] rounded-0",
    hover: "hover:border-b-[#1C64F2] hover:cursor-pointer",
    // active:
    //   "ring-2 active:ring-primary-400 active:bg-primary-400 active:text-primary-200",
    focus: "focus:outline-none focus-visible:border-b-[#1C64F2]",
    disable: "border-[#DDD7E7] cursor-not-allowed ",
  },
};

const LAYOUT_DIRECTION = {
  horizontal: { normal: "flex flex-row items-center", margin: "mr-3" },
  vertical: { normal: "flex flex-col items-start", margin: "mb-3" },
};

export const BACKGROUND_COLOR = {
  white: "bg-white",
  "secondary-600": "bg-secondary-600",
  "secondary-700": "bg-secondary-700",
};
export default function BaseSelectV2({
  label = null,
  direction = "horizontal",
  width = "full",
  options = [],
  value,
  size = "sm",
  disabled = false,
  onChange = () => {},
  variant = "filled",
  textSize = "xs",
  textColor = "primary-100",
  bgColor = "white",
  className,
  placeHolder,
  dataTitle,
  name,
  titleStyle = "",
  isDisableSelectOption = true,
}) {
  const [selected, setSelected] = useState("");

  const onChangeValue = (value) => {
    setSelected(value);
  };

  useEffect(() => {
    if (!value) {
      setSelected("");
    }
  }, [value]);

  useEffect(() => {
    if (selected) {
      onChange(selected, name ? name : "countryCode");
    }
  }, [selected, name]);

  return (
    <div
      className={`h-full relative w-${width} ${LAYOUT_DIRECTION[direction].normal}`}
    >
      {label && (
        <div
          className={`  text-secondary-500 text-body-2 md:text-body-1 font-normal ${LAYOUT_DIRECTION[direction].margin}`}
        >
          {label}
        </div>
      )}

      <Listbox value={selected} onChange={onChangeValue} disabled={disabled}>
        <div className="relative flex-1 h-full">
          <Listbox.Button
            className={`${className} h-full relative flex justify-between items-center w-full cursor-default ${
              BACKGROUND_COLOR[bgColor]
            } ${TEXT_SIZE[textSize]} ${TEXT_COLOR[textColor]} text-left ${
              COLOR_SELECT[variant].normal
            } ${COLOR_SELECT[variant][disabled ? "disable" : "hover"]} ${
              COLOR_SELECT[variant].focus
            } ${SIZE_SELECT[size]}`}
          >
            <span
              className={` ${titleStyle} block truncate ${
                placeHolder && !value ? "text-neutral-300" : "text-neutral-800"
              }`}
            >
              {placeHolder && !value ? placeHolder : value?.slice(0, 14)}
            </span>
            <span className="flex items-center pointer-events-none">
              <IconDown className="h-5 w-5 text-[#6B7280]" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-[10000000000000] w-full py-1 mt-1 overflow-auto bg-white rounded-md text-body-1 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-body-2">
              {options.map((option, personIdx) => (
                <Listbox.Option
                  disabled={
                    (option?.numberCanBuy <= 0 ||
                      option?.remainingQuantity <= 0 ||
                      option?.isBlock) &&
                    isDisableSelectOption
                      ? true
                      : false
                  }
                  key={personIdx}
                  className={({ active }) =>
                    `${
                      (option?.numberCanBuy <= 0 ||
                        option?.remainingQuantity <= 0 ||
                        option?.isBlock) &&
                      isDisableSelectOption
                        ? "cursor-not-allowed bg-neutral-300 rounded-sm  "
                        : "cursor-pointer"
                    } relative flex flex-row  select-none py-2 px-4 text-gray-900 ${
                      active ? "bg-gray-100" : "bg-inherit"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`${
                          (option?.numberCanBuy <= 0 ||
                            option?.remainingQuantity <= 0 ||
                            option?.isBlock) &&
                          isDisableSelectOption
                            ? "text-neutral-400"
                            : "text-gray-500"
                        }  block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                        // style={{
                        //   color: option?.textColor,
                        //   backgroundColor: option?.backgroundColor,
                        // }}
                      >
                        {typeof dataTitle === "function"
                          ? dataTitle(option)
                          : option.name}
                      </span>

                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
