import { motion } from "framer-motion";
import parse from "html-react-parser";
import { t } from "i18next";
import Link from "next/link";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useLanguage } from "../../hooks/useLanguage";
import { formatDate, formatTimeMonth } from "../../utils/helper";
import BaseImage from "../baseImage/BaseImage";

const cardVariants = {
  offscreen: {
    y: 35,
    opacity: 0.85,
  },
  onscreen: {
    y: 10,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1,
    },
  },
};
function CardBlog({ event }) {
  const { nameWithLanguage, descriptionWithLanguage } = useLanguage();
  const { user } = useContext(AuthContext);

  const buildLinkRedirect = () => {
    // if (event?.status === "ENDED" && event?.category === "SPORT") {
    //   return `/match-result/${event?.slug}`;
    // }
    if (event?.waitingRoom && event?.waitingRoom?.roomUrl) {
      return event?.waitingRoom?.roomUrl;
    }
    return `/event/${event?.slug}`;
  };
  if (event) {
    return (
      <Link
        href={buildLinkRedirect()}
        key={event.slug}
        data-aos="fade-up"
        data-aos-once="true"
      >
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <motion.div variants={cardVariants}>
            <div className="w-full h-86.75 overflow-hidden flex flex-col rounded-large hover:scale-[1.02] hover:cursor-pointer duration-500 bg-white">
              <div className="overflow-hidden relative h-[170px] md:min-h-[152px] rounded-t-large ">
                <BaseImage
                  backgroundSize="cover"
                  src={event.image}
                  className="h-full"
                />
                {event.isFeatured && (
                  <p className="absolute px-3 py-1 font-normal text-white rounded-40 top-2 left-2 text-body-2 bg-gradient-to-r from-primary-700 to-primary-800 ">
                    {t("recommended")}
                  </p>
                )}
              </div>
              <div className="flex gap-2.5 p-6 ">
                <div>
                  <p className="mb-1 font-bold uppercase text-body-2 text-primary-200">
                    {formatTimeMonth(event.startTime)}
                  </p>
                  <p className="font-bold text-purple-800 text-heading-3">
                    {formatDate(event.startTime)}
                  </p>
                </div>
                <div className="space-y-2">
                  <div
                    className="rounded-sm w-fit"
                    style={{
                      backgroundColor: event?.subCategory.backgroundColor,
                    }}
                  >
                    <p
                      className="px-2 font-normal text-body-2"
                      style={{
                        color: event?.subCategory.textColor,
                      }}
                    >
                      {event?.subCategory
                        ? event?.subCategory[nameWithLanguage]
                        : ""}
                    </p>
                  </div>
                  <p className="font-bold text-body-1 text-primary-500 line-clamp-2">
                    {event[nameWithLanguage]}
                  </p>
                  <div className="overflow-hidden text-spanish_gray line-clamp-2 _line-clamp_2">
                    {parse(event?.metaData?.[descriptionWithLanguage])}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </Link>
    );
  }
}

export default CardBlog;
