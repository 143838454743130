import React from "react";
import ContentLoader from "react-content-loader";

export default function ThreeDotLoading({ width = 400, size = 20 }) {
  return (
    <ContentLoader
      viewBox="0 0 400 160"
      height={160}
      width={width}
      backgroundColor="transparent"
    >
      <circle cx="100" cy="86" r={size} />
      <circle cx="200" cy="86" r={size} />
      <circle cx="300" cy="86" r={size} />
    </ContentLoader>
  );
}
