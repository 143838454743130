import React, { memo, useContext, useRef, useState } from "react";
import Slider from "react-slick";
import { NextIcon, PrevIcon } from "../../constant/icon";
import { DataContext } from "../../contexts/DataContext";
import { useLanguage } from "../../hooks/useLanguage";
import BaseImage from "../baseImage/BaseImage";
import ThreeDotLoading from "../eventManagement/ThreeDotLoading";
import Link from "next/link";

function Banner() {
  const { currentLanguage } = useLanguage();
  const { imagesBanner, isLoadingImagesBanner } = useContext(DataContext);

  const carouselRef = useRef();
  const [slickActive, setSlickActive] = useState(0);

  const handleNextClick = () => {
    carouselRef.current.slickPrev();
  };

  const handlePrevClick = () => {
    carouselRef.current.slickNext();
  };

  const handleChangeIndex = (index) => {
    setSlickActive(index);
    carouselRef.current.slickNext(index);
  };

  return (
    <div
      style={{ direction: "initial" }}
      className="relative overflow-hidden sm:mx-10 lg:mx-20 h-60 sm:h-96 lg:h-530"
    >
      {isLoadingImagesBanner ? (
        <div className="flex items-center justify-center w-full h-full">
          <ThreeDotLoading size={15} width={300} />
        </div>
      ) : (
        <>
          <div className="absolute flex items-end justify-center w-full bottom-5">
            <div className="absolute z-10 flex items-center bg-neutral-800 opacity-60 rounded-16">
              <div className="cursor-pointer" onClick={handleNextClick}>
                <PrevIcon />
              </div>
              <div className="flex gap-2 px-3">
                {imagesBanner.map((dot, index) => (
                  <div
                    key={index}
                    className={`w-2 hover:scale-150 h-2 ${
                      slickActive === index ? "bg-purple-300" : "bg-neutral-900"
                    }  rounded-full cursor-pointer `}
                    onClick={() => handleChangeIndex(index)}
                  ></div>
                ))}
              </div>
              <div className="cursor-pointer" onClick={handlePrevClick}>
                <NextIcon />
              </div>
            </div>
          </div>
          <Slider
            swipe={false}
            ref={carouselRef}
            arrows={false}
            infinite={true}
            autoplay={true}
            autoplaySpeed={5000}
            duration={1000}
            onChange={(_, index) => handleChangeIndex(index)}
            className="overflow-hidden rounded-none md:rounded-16"
            afterChange={(index) => {
              setSlickActive(index);
            }}
            rtl={currentLanguage === "ar" ? true : false}
          >
            {imagesBanner.map((data, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-none h-60 sm:h-96 lg:h-530 md:rounded-16"
              >
                <Link href={`event/${data.slug}`}>
                  <BaseImage
                    backgroundSize={"cover"}
                    src={data?.url}
                    className={"rounded-none md:rounded-16 overflow-hidden"}
                  />
                </Link>
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  );
}

export default memo(Banner);
