import React from "react";
import { NotFoundIcon } from "../../constant/icon";
import { useTranslation } from "react-i18next";

export default function NoFound() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center pb-16">
      <div className="flex flex-col">
        <div className="h-56 md:h-[320]">
          <NotFoundIcon />
        </div>
        <p className="pt-10 font-bold text-center text-title-1 text-neutral-700">
          {t("not_results_found")}
        </p>
        <p className="text-center text-body-1 text-neutral-600">
          {t("description_not_found")}
        </p>
      </div>
    </div>
  );
}
